// https://github.com/desandro/colcade
.masonry {
  margin: 0 -1%;

  @apply sm:flex pt-xs xs:pt-rg;
}

.masonry__column {
  margin: 0% 1%;
  flex: 1;
  min-width: 0;
}

.masonry__column--2 {
  @apply hidden sm:block;
}

.masonry__column--3 {
  @apply hidden md:block;
}

.masonry__column--4 {
  @apply hidden lg:block;
}

.masonry__item {
  margin: 0 0 20px;
  transition: opacity 0.1s ease-in-out;
  opacity: 1;

  // Ensures that new items have correct width before getting added to masonry column.
  // See https://github.com/cookpad/global-web/pull/17828
  @apply min-w-full sm:min-w-1/2 md:min-w-1/3 lg:min-w-1/4;

  &--new {
    padding: 0% 1%;

    @apply sm:opacity-0;
  }
}
