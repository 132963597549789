// Media object, appropriated from Bootstrap
.media {
  @apply flow-root;

  &,
  &__body {
    overflow: visible;
  }

  &__body {
    width: 10000px;
    display: table-cell;
    vertical-align: top;
    word-break: break-word;

    &--row {
      display: table-row;
    }
  }

  &__img {
    float: left;
    margin-right: 10px;
  }

  &__img-rev {
    float: right;
    margin-left: 10px;
  }
}

a.media {
  display: block;
}
