// TailwindCSS Migration
$tw-cookpad-gray-100: #fcfcfb;
$tw-cookpad-gray-200: #f6f5f4;
$tw-cookpad-gray-300: #ecebe9;
$tw-cookpad-gray-400: #cececd;
$tw-cookpad-gray-500: #939290;
$tw-cookpad-gray-600: #767676;
$tw-cookpad-gray-700: #4a4a4a;
$tw-cookpad-green-500: #81b000;
$tw-cookpad-orange-500: #ff9933;
$tw-cookpad-orange-800: #ec702b;
$tw-cookpad-orange-900: #e25727;
$tw-cookpad-red-500: #fe463a;
$tw-cookpad-red-900: #c01f1e;

// Utilities
$white: #ffffff;
$black: #000000;
