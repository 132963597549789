// Apply placeholder color to everything, not just boostrap forms
input,
textarea {
  @include placeholder;

  text-align: left;
}

a {
  -webkit-tap-highlight-color: rgb(0 0 0 / 30%);
}

// Temporary replacement for the checkbox CSS rules in bootstrap/forms.scss
// until can modify/remove `simple_form_bootstrap` without breaking admin
.boolean input {
  margin-right: 0.5em;
}
