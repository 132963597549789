// Set of "squarish" inputs, ie main search, login, signup
.form-alternate {
  &--error {
    @apply text-cookpad-red-900;
  }
}

// Autosized textareas don't want drag handles
textarea[style] {
  -ms-overflow-style: none;
  resize: none;
}

.oneline-form {
  &__oval {
    @apply flex rounded-xl border border-cookpad-gray-400 p-xxs;
  }

  &__input_wrapper {
    flex-grow: 1;
    margin-right: 5px;
  }

  &__input {
    border: 0;
    background: none;
    box-shadow: none;
  }

  &__status {
    margin-top: 5px;

    &--error {
      @apply text-cookpad-red-900;
    }

    &--hint {
      @apply font-semibold text-cookpad-14 text-cookpad-orange-500;
    }
  }
}

.oneline-form-submit {
  display: flex;

  &__item {
    margin: 0 0.4em 0 0;

    &:disabled {
      opacity: 0.25;
    }
  }
}
