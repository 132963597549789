.StripeElement {
  @apply border-cookpad-gray-400;
}

.StripeElement--invalid {
  @apply border-cookpad-red-700;
}

.StripeElement--focus {
  @apply border-cookpad-gray-700;
}
