.chips {
  @apply flex flex-wrap items-baseline;
}

.chip {
  @apply inline-flex items-center justify-between;
  @apply px-sm py-xs;
  @apply mr-xs mb-sm;
  @apply text-cookpad-14 font-medium;
  @apply text-cookpad-gray-700 text-center;
  @apply leading-snug tracking-tight;
  @apply rounded-lg;
  @apply cursor-pointer;
  @apply bg-cookpad-gray-300;

  &:hover {
    @apply bg-cookpad-gray-400;
  }
}

.chip__icon--left {
  @apply mr-xs;
}

.chip__icon--right {
  @apply ml-xs;
}

.chip-peer {
  @apply hidden;

  &:checked + .chip + .chip-cta,
  &:checked + .chip + .chip-panel,
  &:checked + .chip + .chip-cta + .chip-panel {
    @apply block;
  }
}

.chip-cta {
  @apply hidden order-last;
}

.chip-panel {
  @apply hidden order-last;
}

.chip-peer:checked + .chip {
  @apply bg-cookpad-orange-100;

  &:hover {
    @apply bg-cookpad-orange-200;
  }
}

.chip-peer:disabled + .chip {
  @apply opacity-50;
}

.chip--cookpad-orange-500 {
  @apply border border-cookpad-orange-500;
  @apply bg-cookpad-orange-500;
  @apply text-cookpad-white;

  &:hover {
    @apply bg-cookpad-orange-500-dark-6 border-cookpad-orange-500-dark-6;
  }

  &:focus {
    @apply bg-cookpad-orange-500-dark-12 border-cookpad-orange-500-dark-12;
  }

  &:active {
    @apply bg-cookpad-orange-500-dark-16 border-cookpad-orange-500-dark-16;
  }
}

.chip--cookpad-gray-400-outline {
  @apply border border-cookpad-gray-400;
  @apply bg-cookpad-white;
  @apply text-cookpad-gray-700;

  &:hover {
    @apply bg-cookpad-gray-250;
  }

  &:focus {
    @apply bg-cookpad-gray-350;
  }

  &:active {
    @apply bg-cookpad-gray-375;
  }
}
