.mention {
  @apply inline-block text-cookpad-orange-800;
}

.page-title {
  @apply text-cookpad-gray-700 font-semibold m-0 md:my-rg text-cookpad-16 md:text-cookpad-32;
}

.strong {
  @apply font-semibold;

  b {
    @apply font-normal;
  }
}

.highlighted-word {
  @apply font-semibold text-cookpad-gray-700;
}
