@import "tailwindcss/base";

// Mixins: Site variables and utility functions
@import "~@webpackCSS/mixins/colors";

// Vendor: bootstrap, external stuff
@import "~@webpackCSS/vendor/all";

$card-like-box-shadow: 0 1px 5px rgb(0 0 0 / 10%);

/* Base styles */
html,
body {
  @apply text-cookpad-16;
}

body {
  @apply text-cookpad-gray-700;

  font-family:
    noto-sans,
    system-ui,
    -apple-system,
    "Segoe UI",
    Roboto,
    Helvetica,
    arial,
    sans-serif;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-feature-settings: "liga" on;

  //　Prevent scrolling the back when the modal is open
  &:has(
      #bookmark-folders-select-chip
        [data-dropdown-target="menu"]
        [data-visibility-watch-visibility-state-value="true"]
    ) {
    @apply overflow-hidden;
  }

  &:has(
      [id^="bookmark-folders-edit-button-sp"]
        [data-dropdown-target="menu"]
        [data-visibility-watch-visibility-state-value="true"]
    ) {
    @apply overflow-hidden sm:overflow-auto;
  }

  @media (max-width: 969px) {
    &:has(.sidebar-navigation--active) {
      @apply overflow-hidden;
    }
  }
}

small {
  @apply text-cookpad-12;
}

/* Hide borders around content editable divs
 * We need these over normal inputs to support making ingredient amounts bold */
[contenteditable] {
  @apply outline-none;

  min-height: 1.2em;
}

/* Components */
@import "tailwindcss/components";
@import "~@webpackCSS/components/bottom_navigation";
@import "~@webpackCSS/components/buttons";
@import "~@webpackCSS/components/chips";
@import "~@webpackCSS/components/clamp_base";
@import "~@webpackCSS/components/forms";
@import "~@webpackCSS/components/masonry";
@import "~@webpackCSS/components/media";
@import "~@webpackCSS/components/stripe_elements";
@import "~@webpackCSS/components/text_styles";

/* Main content */

.main-container {
  @apply relative // Needed to allow user page header to stretch across whole page
    print:static
    min-h-screen
    print:shadow-none;
}

.main-content {
  @apply md:mx-auto md:max-w-main-content
    print:max-w-none print:top-header print:absolute;
}

.main-content--full-width {
  @apply max-w-none;
}

/* Alerts */
.alert {
  @apply bg-cookpad-gray-200;
  @apply p-sm;
  @apply overflow-hidden;
  @apply text-cookpad-gray-700;
}

.alert--success {
  @apply text-cookpad-white bg-cookpad-green-500;
}

.alert--danger {
  @apply text-cookpad-white bg-cookpad-red-500;
}

.alert__link {
  @apply font-semibold;
}

/* Cards */
.card {
  @apply bg-cookpad-white;
  @apply rounded;
  @apply border;
  @apply border-cookpad-gray-400;

  box-shadow: $card-like-box-shadow;
}

/* Speech Bubbles */
.speech-bubble {
  @apply bg-cookpad-white text-cookpad-gray-700 p-rg rounded-md inline-block relative;
}

.speech-bubble::after {
  @apply absolute top-0 right-0 -mr-sm -mb-rg border-solid border-transparent border-8;

  content: "";
  border-top-color: white;
}

/* Labels */
.label {
  @apply bg-cookpad-gray-600;
  @apply text-cookpad-white text-cookpad-12;
  @apply px-xs;
  @apply rounded;
  @apply whitespace-nowrap;

  padding-top: 0.15rem; /* TODO: temporarily using padding value not found in scale */
  padding-bottom: 0.15rem; /* TODO: temporarily using padding value not found in scale */
}

.top-curve-shadow {
  filter: drop-shadow(0 -0.15rem 0.075rem rgb(0 0 0 / 25%));
}

/* Headings */
.h1 {
  @apply text-cookpad-36 font-semibold;
}

.h2 {
  @apply text-cookpad-28 font-semibold;
}

.h3 {
  @apply text-cookpad-24 font-semibold;
}

.h4 {
  @apply text-cookpad-16 font-semibold;
}

/* Styled Links */
.link,
.links a {
  @apply text-cookpad-orange-800;
}

.link:hover,
.link:focus,
.links a:hover,
.links a:focus {
  @apply text-cookpad-orange-900;
  @apply underline;
}

/* Inputs */
.editor-input {
  @apply px-sm py-sm bg-cookpad-gray-200 rounded;
}

/* Image File Uploads */
.image-upload {
  @apply relative overflow-hidden cursor-pointer;

  input {
    @apply absolute inset-0 m-0 opacity-0 cursor-pointer h-full w-full;
  }
}

/* Drop Images on File Uploads */
.droppable {
  &--dropping {
    @apply relative;

    &::after {
      @apply absolute top-0 left-0 w-full h-full opacity-40 bg-cookpad-white border-2 border-dashed border-cookpad-gray-500;

      content: "";
    }
  }

  /* Hides borders and placeholder for non-authors */
  .editor:not(.editor--pc) & {
    &--dropping {
      &::after {
        visibility: hidden;
      }
    }
  }

  &--circle {
    &.droppable--dropping::after {
      @apply rounded-full;
    }
  }
}

/* Images */
.tofu_image {
  @apply block max-w-full relative h-0 overflow-hidden;

  img {
    @apply w-full h-full absolute block left-0 top-0;
  }
}

.image {
  @apply relative;

  min-height: 40px; /* Ensure image is tall enough to contain Dismiss button */
}

/* Text */
.blockquote {
  @apply relative pl-rg;
}

.blockquote::before {
  content: "";

  @apply w-1 bg-cookpad-gray-400 block rounded-sm top-0 left-0 bottom-0 absolute;
}

/* Slider */
.swiper-pagination-bullet {
  @apply mx-px rounded-full opacity-50 inline-block;

  width: 6px;
  height: 6px;
}

.swiper-pagination-bullet--active,
.swiper-pagination-bullet:hover {
  @apply opacity-100 mx-0;

  padding: 1px;
  width: 8px;
  height: 8px;
}

.swiper-pagination-bullet:focus {
  @apply outline-none;
}

.swiper-slide-active {
  @apply z-20;
}

.expert-description > a {
  @apply underline;
}

/* Fade out border */
.fade-out-border-top,
.fade-out-border-bottom {
  @apply relative;
}

.fade-out-border-top::before {
  content: "";

  @apply h-20 w-full absolute top-0;
  @apply bg-gradient-to-t from-white-transparent to-cookpad-gray-200;
}

.fade-out-border-bottom::after {
  background-image: linear-gradient(
    theme("colors.white-transparent"),
    theme("colors.cookpad-gray.200") 20%,
    theme("colors.cookpad-gray.200")
  );
  content: "";

  @apply h-20 w-full absolute bottom-0 -mb-lg;
}

/* Large document with separate panels (recipe, author profile, comments)
 * that can contain sections (recipe story, ingredients, steps) */
.document-title-container {
  @apply my-sm;
}

.document-panel {
  @apply mx-rg;

  @screen xs {
    @apply mx-md;
  }
}

.document-section {
  @apply p-sm;

  @screen xs {
    @apply p-rg;
  }
}

/* Dropdown */
.dropdown-menu {
  @apply shadow-xl;
  @apply min-w-full;
  @apply bg-cookpad-white;
  @apply rounded;
  @apply border border-cookpad-gray-400;
  @apply overflow-x-hidden;
}

.dropdown-menu__item-icon {
  @apply text-cookpad-20;
  @apply pr-sm;
}

.dropdown-menu__item-link {
  @apply flex items-center;
  @apply p-sm;
  @apply w-full;
}

/* drag and drop */
.drag {
  @apply relative;
}

.drag::after {
  @apply absolute;
  @apply top-0;
  @apply left-0;
  @apply w-full;
  @apply h-full;
  @apply border-2;
  @apply border-dashed;

  content: "";
}

/* scroll bar */
.scroll-bar-hidden {
  -ms-overflow-style: none; /* IE 10+ */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none; /* Chrome & Safari */
  }
}

/* mise icons */
.mise-icon {
  @apply inline;
}

.mise-icon-arrow-down-right,
.mise-icon-arrow-left,
.mise-icon-arrow-right,
.mise-icon-arrow-trend-down,
.mise-icon-arrow-trend-up,
.mise-icon-arrow-up-right,
.mise-icon-chevron-left,
.mise-icon-chevron-right,
.mise-icon-redo,
.mise-icon-send,
.mise-icon-undo,
.mise-icon-double-arrow-left {
  [dir="rtl"] & {
    @apply transform -scale-x-1;
  }
}

.mise-icon-text {
  @apply align-middle;
}

/* lazy loading */
.lazyload,
.lazy-swiper {
  @apply opacity-0;
}

.lazyloaded,
.lazy-swiper--loaded {
  @apply transition-opacity;
  @apply duration-500;
  @apply opacity-100;
}

/* animation */
.fade-in-out {
  opacity: 0;
  visibility: hidden;
}

.fade-in-out--fading {
  opacity: 0.1; /* make clickable for specs https://github.com/cookpad/global-web/pull/23185 */
  visibility: visible;
  animation: fade-in-out 4s ease-in-out;
  animation-fill-mode: forwards;
}

@keyframes fade-in-out {
  0%,
  100% {
    visibility: hidden;
    opacity: 0;
  }
  1%,
  99% {
    visibility: visible;
  }
  10%,
  90% {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0;
  display: none;
}

.fade-in--up {
  opacity: 0.1; // make element visible for specs due to app/views/layouts/_tests.html.erb
  display: block;
  animation: fade-in 0.5s;
  animation-fill-mode: forwards;
}

@keyframes fade-in {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.hyphenate {
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

.transparent-read-more {
  background: linear-gradient(180deg, transparent, theme("colors.cookpad-gray.50") 50%);
  max-height: 70%;
}

.clamp-1 {
  @include clamp-base;

  -webkit-line-clamp: 1;
}

.clamp-2 {
  @include clamp-base;

  -webkit-line-clamp: 2;
}

.clamp-3 {
  @include clamp-base;

  -webkit-line-clamp: 3;
}

.clamp-4 {
  @include clamp-base;

  -webkit-line-clamp: 4;
}

.clamp-5 {
  @include clamp-base;

  -webkit-line-clamp: 5;
}

.clamp-8 {
  @include clamp-base;

  -webkit-line-clamp: 8;
}

.clamp-9 {
  @include clamp-base;

  -webkit-line-clamp: 9;
}

/* Allow Safari to handle <p> tags */
.clamp-rich-text {
  &:not(.clamp-none-on-print) {
    p {
      margin: 0 !important;
    }
  }

  p:not(:first-child) {
    display: none;
  }
}

/* Switch */
.switch:checked ~ span:last-child {
  @apply translate-x-md;

  [dir="rtl"] & {
    @apply -translate-x-md;
  }
}

/* Popover */
.popover {
  @apply bg-cookpad-gray-700 text-cookpad-white font-semibold p-sm text-cookpad-12 rounded-md;
}

.popover__arrow,
.popover__arrow::before {
  @apply absolute;

  width: 8px;
  height: 8px;
  z-index: -1;
}

.popover__arrow::before {
  content: "";

  @apply transform rotate-45 bg-cookpad-gray-700;
}

.popover[data-popper-placement^="top"] > .popover__arrow {
  bottom: -4px;
}

.popover[data-popper-placement^="bottom"] > .popover__arrow {
  top: -4px;
}

.popover[data-popper-placement^="left"] > .popover__arrow {
  right: -4px;
}

.popover[data-popper-placement^="right"] > .popover__arrow {
  left: -4px;
}

/* Block link */
.block-link {
  @apply cursor-pointer relative;
}

.block-link__main::before {
  content: "";

  @apply absolute top-0 left-0 w-full h-full;
}

@media (max-width: 767px) {
  .block-link__sp-only {
    @apply cursor-pointer relative;
  }

  .block-link__main__sp-only::before {
    content: "";

    @apply absolute top-0 left-0 w-full h-full;
  }
}

.block-link__exclude {
  @apply z-10;
}

.block-link__exclude-positioned {
  @apply z-10 pointer-events-none;
}

/* User-form */
.user-form-label {
  @apply font-semibold inline-block mb-sm;
}

.user-form-input {
  @apply block;
  @apply text-cookpad-16;
  @apply p-0;
  @apply w-full;
  @apply border-0;
  @apply bg-transparent;
  @apply rounded-none;
  @apply shadow-none;
  @apply text-cookpad-gray-700;
  @apply placeholder-cookpad-gray-400;
  @apply focus:outline-none;
}

.user-form-group > * {
  @apply border-cookpad-gray-400;
}

.user-form-group--error > * {
  @apply border-cookpad-red-400;
}

/* Centerable */
.centerable {
  @apply relative;
}

.centerable__item {
  @apply absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2;

  [dir="rtl"] & {
    @apply translate-x-1/2;
  }
}

/* Kaminari pagination */
.pagination {
  @apply text-center text-cookpad-16;
}

.pagination__page {
  @apply mx-sm;
}

/* Custom backgrounds */
.background-confetti-stars-and-springs {
  background-image: url("~@assetImage/confetti_stars_and_springs");
}

.background-gold-confetti-and-cameras {
  background-image: url("~@assetImage/gold_confetti_and_cameras.svg");
}

.background-premium-intercept {
  background-image: url("~@assetImage/premium_intercept_bg.jpg");
}

.background-recipe-intercept {
  background-image: url("~@assetImage/recipe_intercept_bg.jpg");
}

/* Mention suggestions */
.mention-dropdown {
  @apply h-auto w-80 overflow-auto block z-50 max-h-80;
}

.mention-dropdown > ul {
  @apply mt-sm;
}

.mention-dropdown__item--highlight > a {
  @apply bg-cookpad-gray-200;
}

/* Reactions */

.reaction {
  @apply inline float-left align-middle;
}

.reacters-tab {
  @apply text-cookpad-gray-400;
}

.reacters-tab--active {
  @apply border-b-2 border-cookpad-orange-500 text-cookpad-gray-700;
}

/* Use to keep partial cacheable but allowing them to have different icons depending on position in DOM */

.ranked-list__rank {
  display: none;
}

.ranked-list .ranked-list__item:nth-child(-1n + 3) .ranked-list__rank {
  display: block;
}

.ranked-list .ranked-list__item:nth-child(1) .ranked-list__rank-icon {
  background-image: url("~@assetImage/legacy_symbols/rank1.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.ranked-list .ranked-list__item:nth-child(2) .ranked-list__rank-icon {
  background-image: url("~@assetImage/legacy_symbols/rank2.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.ranked-list .ranked-list__item:nth-child(3) .ranked-list__rank-icon {
  background-image: url("~@assetImage/legacy_symbols/rank3.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

/* Loading Spinner */

.loading {
  @apply relative;
  @apply pointer-events-none;
  @apply opacity-60;
}

.spinner {
  @apply animate-spin h-4 w-4 inline-block border-2 rounded-full opacity-60;

  border-color: black black white white;
}

/* Forms */

.field-with-icon {
  @apply mb-rg;
}

.field-with-icon__input-group {
  @apply shadow-xs;
  @apply border-0;
  @apply rounded;
  @apply flex items-center;
  @apply bg-cookpad-white;
  @apply text-cookpad-gray-500;
  @apply text-cookpad-16;
}

.field-with-icon--error .field-with-icon__input-group {
  @apply bg-cookpad-red-50;
  @apply text-cookpad-red-900;
}

.field-with-icon__icon {
  @apply p-sm;
  @apply text-cookpad-16;
}

.field-with-icon__input {
  @apply w-full;
  @apply bg-transparent;
  @apply focus:outline-none;
}

.field-with-icon--error .field-with-icon__input::placeholder {
  @apply text-cookpad-red-900;
}

.field-with-icon__hint {
  @apply text-cookpad-red-900;
  @apply text-cookpad-14;
}

.custom-select {
  @apply appearance-none;

  background: url("~@webpackImage/icons/mise/chevron_down.svg") no-repeat center right;
}

.visible-inside-disabled {
  display: none;
}

.visible-inside-enabled {
  display: initial;
}

[disabled] {
  .visible-inside-disabled {
    display: initial;
  }

  .visible-inside-enabled {
    display: none;
  }
}

/* Autocomple dropdown */

.selected {
  .autocomplete-suggestion-item {
    @apply bg-cookpad-gray-200
      border-cookpad-orange-500/100
      current-user-premium-badge:border-cookpad-premium-gold-500/100;
  }
}

.autocomplete-suggestion-item {
  @apply border-l-2
    text-cookpad-gray-700
    hover:bg-cookpad-gray-200
    border-cookpad-orange-500/0
    current-user-premium-badge:border-cookpad-premium-gold-500/0;
}

/* Table View */

.table-view {
  @apply border-b border-solid border-cookpad-gray-400;
}

.table-view--no-end-borders {
  @apply border-b-0;
}

.table-view--no-end-borders .table-view__item:first-child {
  @apply border-t-0;
}

.table-view__item {
  @apply text-cookpad-gray-700;
  @apply bg-cookpad-white;
  @apply border-t border-solid border-cookpad-gray-400;
  @apply block;
  @apply relative;
  @apply py-sm px-rg;
  @apply m-0;
}

.table-view__item--highlighted {
  @apply bg-cookpad-gray-200;
}

.table-view__heading {
  @apply m-0;
  @apply leading-6;
  @apply font-semibold;
}

.search-form-scroll-button {
  opacity: 0;
  visibility: hidden;

  @screen lg {
    opacity: 1;
    visibility: visible;
  }
}

.search-form-scroll-button--intersecting {
  @screen lg {
    opacity: 0;
    visibility: hidden;
  }
}

.recipe-preview {
  @apply max-h-72;
  @apply overflow-y-hidden;
}

.recipe-preview-expand {
  @apply absolute -bottom-px h-36 w-full;
  @apply flex justify-center items-end;
  @apply bg-gradient-to-t from-cookpad-white via-cookpad-white;

  a {
    @apply py-sm px-rg mb-md;
    @apply border-2 border-solid border-cookpad-gray-400 rounded-md;
    @apply font-medium text-cookpad-16;
  }
}

.bg-premium-chevron {
  background-image: url("~@assetImage/premium/background_chevron.svg");

  @apply bg-cover bg-no-repeat bg-bottom;
  @apply pb-lg;
}

.numbered-list {
  @apply list-none;

  counter-reset: numList;
}

.numbered-list > * {
  @apply mb-sm;
}

.numbered-list__item:not(.ui-sortable-placeholder)::before {
  @apply flex;
  @apply flex-col;
  @apply justify-center;
  @apply float-left;
  @apply w-6 h-6;
  @apply rounded-full;
  @apply bg-cookpad-gray-700;
  @apply text-cookpad-white;
  @apply text-center;
  @apply text-cookpad-14;
  @apply leading-none;
  @apply mr-sm;

  counter-increment: numList;
  content: counter(numList);
}

.numbered-list__contents {
  @apply relative;
  @apply overflow-auto;
}

.anchor {
  @apply block relative invisible;

  top: -5rem;
}

.toggle--active .toggle__inactive {
  @apply hidden;
}

.toggle--inactive .toggle__active {
  @apply hidden;
}

/*
  Fixes a bug in medium editor where you need to click on the
  placeholder twice to edit the field

  https://github.com/cookpad/product-delivery/issues/4692
*/
.medium-editor-placeholder {
  &::before {
    @apply float-left pointer-events-none text-cookpad-gray-400;

    content: attr(data-placeholder);
  }
}

.navigation-menu__close-button {
  @apply absolute right-rg;

  top: 70px;
}

@media (min-width: 768px) {
  .navigation-menu__close-button {
    top: 17px;
  }
}

/* Available in TailwindCSS 3.x.x */
.basis-full {
  flex-basis: 100%;
}

.trending-recipes-header {
  @apply mx-auto h-32 mb-md;

  background-image: url("~@assetImage/trending_recipes_illustration");
  background-position: center bottom;
  background-repeat: no-repeat;
}

.trending-recipes-landing-header {
  background-image: url("~@assetImage/trending_recipes/landing_hero_image.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg-trending-recipes-unlock-wave {
  background-image: url("~@assetImage/trending_recipes_unlock_wave_bg.svg");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}

.sticky-trending-recipes-sidebar {
  @apply sticky;

  top: 7rem;
}

.bg-trending-recipe-unlock {
  background: linear-gradient(
    133.95deg,
    theme("colors.cookpad-orange.500") 19.51%,
    theme("colors.cookpad-orange.900") 80.19%
  );
}

.trending-recipes-shadow {
  box-shadow: $card-like-box-shadow;
}

.recipe-card-shadow {
  box-shadow: $card-like-box-shadow;
}

.trending-recipes-flag-card {
  width: 5rem;
  height: 12rem;
}

.trending-recipes-recipe-card {
  width: 10rem;
  height: 12rem;
}

.trending-recipes-see-all-card {
  width: 5rem;
}

@media (min-width: 768px) {
  .trending-recipes-flag-card {
    width: 7.5rem;
    height: auto;
  }

  .trending-recipes-recipe-card {
    width: 15rem;
    height: auto;
  }

  .trending-recipes-see-all-card {
    width: 7.5rem;
  }
}

.cookbook-cover-size {
  width: 156px;
  height: 208px;

  &-xs {
    width: 48px;
    height: 64px;
  }
}

.homepage-download-reason-position-1 {
  @apply absolute left-xs;

  top: 36px;
}

.homepage-download-reason-position-2 {
  @apply absolute right-xs;

  top: 115px;
}

.homepage-download-reason-position-3 {
  @apply absolute bottom-xs;
}

.recipe-card-size {
  max-width: 160px;
}

// Ingredient Separate View (full justification)

.justified-quantity-and-name {
  @apply flex flex-wrap gap-x-sm gap-y-xxs py-sm;

  @media (min-width: 970px), print {
    padding-top: 6px;
    padding-bottom: 6px;
  }

  > bdi {
    @apply grow basis-0 content-center text-balance min-w-fit;

    overflow-wrap: anywhere;

    &:nth-child(1) {
      @apply text-start;
    }

    &:nth-child(2) {
      @apply text-end;
    }
  }

  &.headline {
    @apply gap-y-0;

    &:not(:first-child) {
      @apply mt-md;
    }
  }

  &.not-headline {
    @apply border-b border-cookpad-gray-300 print:border-cookpad-gray-300 lg:border-cookpad-gray-400 border-dashed;

    &:last-child {
      @apply mb-sm;
    }
  }
}

.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
  // deprecated property. for browsers that don't support overflow-wrap: anywhere
  word-break: break-word;
}

.recipe-card-adjust-width {
  width: calc((100% - 4 * 1rem) / 3);
}

.responsive-image {
  width: 100%;
  height: auto;
  max-width: 100%;
  object-fit: cover;
  aspect-ratio: 4 / 3;
}

.premium-meal-bg {
  background-image: url("~@assetImage/premium/meal_plan/bg_dining_table.jpg");
}

.navigation-container:has(.sidebar-navigation) {
  @screen lg {
    @apply px-sm;

    grid-template-columns: clamp(240px, 20%, 270px) 1fr;

    &:has(.sidebar-navigation--inactive) {
      grid-template-columns: 64px 1fr;
    }
  }
}

.sidebar-navigation {
  @apply fixed z-40 top-0 overflow-auto print:hidden h-screen;

  // sidebar styles
  @screen lg {
    @apply grid print:hidden sticky z-0 overflow-hidden;

    grid-template-rows: max-content 1fr auto;
    transition: unset;
    width: unset;
    margin-left: unset;
  }

  // drawer styles
  margin-left: -100%;
  width: 85%;
  max-width: 321px;
  transition: margin-left 150ms ease-in-out;

  .sidebar-backdrop {
    @apply fixed inset-0 bg-cookpad-gray-700 invisible opacity-0;

    z-index: -1;
    transition: 150ms ease-in-out;
  }

  &--active {
    margin-left: 0;

    .sidebar-backdrop {
      @apply visible opacity-75;
    }
  }
}

.sidebar-navigation-header {
  @screen lg {
    box-shadow: none !important;

    > div:first-child {
      @apply -mt-rg print:mt-0;
    }

    &::before {
      @apply block print:hidden h-md bg-transparent rounded-t-lg relative;

      content: "";
      box-shadow: 0 -1rem 0 0 theme("colors.cookpad-gray.200");
      transform: translateY(0.5rem);
      z-index: -1;
    }
  }
}

.sidebar-masked-bottom-corners {
  @apply h-sm;

  transform: translateY(-0.5rem);

  &::before {
    @apply block print:hidden h-rg bg-transparent rounded-b-lg;

    content: "";
    box-shadow: 0 0.5rem 0 0 theme("colors.cookpad-gray.200");
    transform: translateY(-0.5rem);
  }
}

.new-pc-top--suggested-collection {
  @media (max-width: 599px) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  @media (min-width: 600px) and (max-width: 1033px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));

    > :nth-child(4) {
      display: none;
    }
  }

  @media (min-width: 1034px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

.nagivation--mylibrary--sort-dropdown--shadow {
  box-shadow:
    0 8px 12px rgb(0 0 0 / 6%),
    0 4px 8px rgb(0 0 0 / 2%),
    0 2px 8px rgb(0 0 0 / 10%);
}

.recipe-page-statistics {
  @apply grid gap-sm relative bg-cookpad-white;

  grid-template-rows: auto 1fr auto;

  &.recipe-views-count-zero {
    grid-template-rows: 1fr auto;
  }
}

.cooksnap-0-left {
  transform: translateX(0);
  z-index: 3;
}

.cooksnap-1-left {
  transform: translateX(-8px);
  z-index: 2;
}

.cooksnap-2-left {
  transform: translateX(-16px);
  z-index: 1;
}

.library-folder {
  &:hover {
    @apply bg-cookpad-gray-200;
  }

  &--active {
    @apply bg-cookpad-gray-200;

    .library-folder--title {
      @apply text-cookpad-orange-500;
    }
  }
}

.turbo-progress-bar {
  @apply print:hidden;
}

.search-top-category-list {
  @apply grid grid-cols-3 lg:gap-rg;

  @media (min-width: 1034px) {
    @apply grid-cols-4;
  }

  .search-top-category {
    @apply flex items-center gap-xs py-sm border-b border-cookpad-gray-400;

    @screen lg {
      @apply gap-rg p-rg rounded-lg border-none;

      box-shadow: $card-like-box-shadow;

      &:hover {
        @apply bg-cookpad-gray-200;
      }
    }
  }
}

.similar-recipe-card {
  @apply block rounded-lg border border-cookpad-gray-400 overflow-hidden mb-sm;

  width: calc(50% - 8px);

  @screen sm {
    width: calc(100% / 3 - 8px);
  }

  @screen md {
    width: calc(25% - 8px);
  }

  @media (min-width: 1180px) {
    width: calc(100% / 6 - 8px);
  }
}

.recipe-action-button {
  @media (min-width: 970px) and (max-width: 1254px) {
    @apply px-0;

    border: 0 !important;

    span {
      @apply hidden;
    }

    .premium-icon {
      @apply w-4 h-4 top-0 left-xxs;
    }
  }
}

#header {
  @screen lg {
    &:has(.header--show-border-bottom) {
      @apply border-b border-cookpad-gray-300 opacity-100;
    }
  }
}

.redesign-step-attachments-list {
  @screen lg {
    @apply grid grid-flow-col overflow-auto scroll-bar-hidden;

    grid-auto-columns: 100%;

    > div {
      aspect-ratio: 5 / 4;
    }
  }
}

@import "~@webpackCSS/components/developers";
@import "~@webpackCSS/components/static_pages";
@import "~@webpackCSS/components/paywalls";
@import "tailwindcss/utilities";
