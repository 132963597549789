/* This is an exception to our BEM conventions https://github.com/cookpad/global-web/pull/24281 */
.developers-markdown {
  a {
    @apply link;
  }

  code {
    @apply bg-cookpad-gray-300 p-xs;
  }

  p {
    @apply mb-rg;
  }
}
