// This is an exception to our BEM conventions as the contents are outsourced
.legal {
  @apply px-rg pb-md;

  h1,
  h2,
  h3,
  h4 {
    @apply font-semibold;
    @apply mt-md mb-sm;
  }

  h1 {
    @apply text-cookpad-36;
  }

  h2 {
    @apply text-cookpad-20;
  }

  h3 {
    @apply text-cookpad-16;
  }

  h4 {
    @apply text-cookpad-16;
  }

  p {
    @apply mb-sm;
  }

  li {
    @apply relative mb-sm ml-rg;
  }

  ul > li {
    @apply list-disc;
  }

  ol[type="1"] > li {
    @apply list-decimal;
  }

  a {
    @apply underline;
  }
}

.cookie-usages {
  @apply border border-solid border-cookpad-gray-400;
  @apply w-full;
  @apply table-fixed;
  @apply text-cookpad-14;

  th,
  td {
    @apply border border-solid border-cookpad-gray-400;
    @apply py-xs px-sm;
    @apply break-words;
  }

  td {
    &:nth-child(1) {
      width: 8%;
    }

    &:nth-child(2) {
      width: 12%;
    }

    &:nth-child(3) {
      width: 35%;
    }

    &:nth-child(4) {
      width: 10%;
    }

    &:nth-child(5) {
      width: 35%;
    }
  }

  a {
    @apply break-all;
  }
}

.terms-others {
  @apply my-lg px-[32px] lg:px-0;
}

.terms-others-header {
  @apply mb-lg;

  p {
    @apply mb-sm font-semibold;
  }

  ul {
    @apply mb-md;
  }

  li {
    @apply ml-md list-disc;
  }

  a {
    @apply underline;
  }
}

.terms-others-body {
  @apply py-lg border-t border-cookpad-gray-400 lg:border-cookpad-gray-300 break-words;

  h1 {
    @apply text-cookpad-32 font-semibold my-md;
  }

  h2 {
    @apply text-cookpad-20 font-semibold my-md;
  }

  h3 {
    @apply font-semibold my-md;
  }

  p {
    @apply mb-sm;
  }

  a {
    @apply underline;
  }

  ul {
    @apply ml-rg;
  }

  li {
    @apply mb-sm relative;
  }

  table {
    @apply w-full table-fixed border-collapse border border-cookpad-gray-400 lg:border-cookpad-gray-300;
  }

  th,
  td {
    @apply text-left border border-cookpad-gray-400 lg:border-cookpad-gray-300 py-xs px-sm;
  }

  th {
    @apply w-1/3 font-semibold;
  }

  td {
    @apply w-2/3;
  }
}
