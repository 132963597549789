@mixin clamp-base {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;

  // Allow Safari to truncate links correctly
  a::after {
    content: "";
  }
}
