.btn {
  @apply inline-block;
  @apply py-xs px-sm;
  @apply rounded-lg;
  @apply border border-cookpad-gray-400;
  @apply text-cookpad-gray-600 text-center font-medium;
  @apply text-cookpad-12;
  @apply cursor-pointer;

  @screen xs {
    @apply py-sm px-rg;
    @apply text-cookpad-16 leading-tight;
  }

  &:disabled {
    @apply opacity-50;
    @apply pointer-events-none;
  }
}

.btn__icon {
  @apply mr-xs;
}

/* Variations */
.btn--alt {
  @apply rounded;
  @apply normal-case;
}

.btn--circle {
  @apply p-0;
  @apply rounded-full;
  @apply w-8 h-8;
  @apply relative;

  .btn__icon {
    @apply m-0;
  }
}

.btn--sm {
  @apply py-px;
}

.btn--mini {
  @apply py-px px-xs;

  .btn__icon {
    @apply mr-px;
  }
}

.btn--wide {
  @apply px-lg;
}

.btn--block {
  @apply block w-full;
}

/* Colors */
.btn--cookpad-green-500 {
  @apply border-none;
  @apply bg-cookpad-green-500;
  @apply text-cookpad-white;
}

.btn--cookpad-orange-500 {
  @apply border-none;
  @apply bg-cookpad-orange-500;
  @apply text-cookpad-white;

  &:disabled {
    @apply opacity-100;
    @apply bg-cookpad-gray-400;
  }

  &:hover {
    @apply bg-cookpad-orange-500-dark-6;
  }

  &:focus {
    @apply bg-cookpad-orange-500-dark-12;
  }

  &:active {
    @apply bg-cookpad-orange-500-dark-16;
  }
}

.btn--cookpad-orange-500-outline {
  @apply font-semibold;
  @apply border-cookpad-orange-500;
  @apply text-cookpad-orange-500;
}

.btn--cookpad-red-500-outline {
  @apply font-semibold;
  @apply border-cookpad-red-500;
  @apply text-cookpad-red-500;
}

.btn--light {
  @apply border-none;
  @apply bg-cookpad-gray-300;
  @apply text-cookpad-gray-600;

  &:disabled {
    @apply opacity-100;
    @apply bg-cookpad-gray-50;
    @apply border border-cookpad-gray-300;
  }

  &:hover {
    @apply bg-cookpad-gray-250;
  }

  &:focus {
    @apply bg-cookpad-gray-350;
  }

  &:active {
    @apply bg-cookpad-gray-375;
  }
}

.btn--dark {
  @apply bg-cookpad-gray-700;
  @apply text-cookpad-white;

  &:disabled {
    @apply opacity-100;
    @apply bg-cookpad-gray-400;
  }

  &:hover {
    @apply bg-cookpad-gray-650;
  }

  &:focus {
    @apply bg-cookpad-gray-600;
  }

  &:active {
    @apply bg-cookpad-gray-550;
  }
}

.btn--cookpad-premium-gold-500 {
  @apply border-none;
  @apply bg-cookpad-premium-gold-500;
  @apply text-cookpad-white;

  &:disabled {
    @apply opacity-100;
    @apply bg-cookpad-gray-400;
  }

  &:hover {
    @apply bg-cookpad-premium-gold-600;
  }

  &:focus {
    @apply bg-cookpad-premium-gold-700;
  }

  &:active {
    @apply bg-cookpad-premium-gold-800;
  }
}

.btn--danger {
  @apply border-none;
  @apply bg-cookpad-red-500;
  @apply text-cookpad-white;
}
